<template>
    <span>
        <b-button id="popover-target-ema" size="sm" style="color: white"
                  v-bind:style="{backgroundColor: addEMA === true ? 'darkgray' : 'black' }">
            EMA
        </b-button>
        <b-popover target="popover-target-ema" triggers="click blur" placement="bottom" ref="popover">
           
                <b-container class="container-fluid" style="font-family: verdana; border:1px solid gray; text-align: right; background-color: whitesmoke; width: fit-content; padding-right: 7px ">
                    <b-form-row align-h="start" style="border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: bold; font-size: 1rem">  
                            add EMA:
                            <b-form-select style="border:1px solid gray; width: auto;"
                                           v-model="addEMA" :options="options"></b-form-select>

                        </b-col>
                    </b-form-row>

                    <b-form-row v-if="addEMA" align-h="start" style="border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: bold; font-size: 1rem">  
                            period:&nbsp;
                            <b-form-select style="border:1px solid gray; width: auto;"
                                           v-model="emaLength" :options="emaLengthOptions"></b-form-select>

                            <span style="font-weight: normal">&nbsp;days</span>
                        </b-col>
                    </b-form-row>
                </b-container>
                                     
        </b-popover>
    </span>
</template>

<script>
    module.exports = {
        props: ['namespace'],
        data: function () {
            return {
                emaLengthOptions: Array(30).fill(null).map((x, i) => ({value: i + 1, text: i + 1})),
                options: [
                    {value: true, text: "yes"},
                    {value: false, text: "no"}
                ]
            };
        },
        computed: {
            addEMA: {
                get() {
                    return this.$store.state[this.namespace].addEMA;
                },
                set(addEMA) {
                    this.$store.commit(this.namespace + '/setAddEMA', addEMA);
                }
            },
            emaLength: {
                get() {
                    return  this.$store.state[this.namespace].emaLength;
                },
                set(emaLength) {
                    this.$store.commit(this.namespace + '/setEmaLength', emaLength);
                }
            }
        }
    }
</script>
