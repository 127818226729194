<template>
    <span>
        <b-button id="popover-target-cci" size="sm" style="color: white"
                  v-bind:style="{backgroundColor: addCCIPanel === true ? 'darkgray' : 'black' }">
            CCI
        </b-button>
        <b-popover target="popover-target-cci" triggers="click blur" placement="bottom" ref="popover">
            <b-container class="container-fluid" style="font-family: verdana; border:1px solid gray; padding: 0px; background-color: whitesmoke;
                     width: fit-content; padding-right: 7px; text-align: right">
                <b-form-row align-h="start" style="border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: bold; font-size: .9rem;">  
                        add CCI panel:
                        <b-form-select style="border:1px solid gray; width: auto;"
                                       v-model="addCCIPanel" :options="options"></b-form-select>
                    </b-col>
                </b-form-row>

                <b-form-row v-if="addCCIPanel" align-h="start" style="border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: bold; font-size: .9rem">  
                        period:&nbsp; <b-form-select style="border:1px solid gray; width: auto;"
                                                     v-model="cciPeriod" :options="cciPeriodOptions"></b-form-select>
                        <span style="font-weight: normal">  &nbsp;days</span>
                    </b-col>
                </b-form-row>

                <b-form-row v-if="addCCIPanel" align-h="start" style="border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: bold; font-size: .9rem">  
                        using &nbsp;<b-form-select :disabled="legs > 1"   style="border:1px solid gray; width: auto;"
                                                   v-model="cciSource" :options="cciSourceOptions"></b-form-select>
                    </b-col>
                </b-form-row>
                     <div v-if="addCCIPanel && legs == 1" style="text-align: left; padding: 2px"> typical = (high + low + close)/3  </div>
            </b-container>

        </b-popover>
    </span>
</template>

<script>
    module.exports = {
        props: ['namespace'],
        data: function () {
            return {
                cciPeriodOptions: Array(19).fill(null).map((x, i) => ({value: i + 2, text: i + 2})),
                options: [
                    {value: true, text: "yes"},
                    {value: false, text: "no"}
                ],
                cciSourceOptions: [
                    {value: "closes", text: "closes"},
                    {value: "typical", text: "typical"}
                ]
            }
        },
        computed: {
            addCCIPanel: {
                get() {
                    return this.$store.state[this.namespace].addCCIPanel;
                },
                set(addCCIPanel) {
                    this.$store.commit(this.namespace + '/setAddCCIPanel', addCCIPanel);
                }
            },
            cciPeriod: {
                get() {
                    return this.$store.state[this.namespace].cciPeriod;
                },
                set(cciPeriod) {
                    this.$store.commit(this.namespace + '/setCciPeriod', cciPeriod);
                }
            },
            cciSource: {
                get() {
                    return this.$store.state[this.namespace].legs == 1 ? this.$store.state[this.namespace].cciSource : "closes";
                },
                set(cciSource) {
                    // console.log("cciSource=", cciSource);
                    this.$store.commit(this.namespace + '/setCciSource', cciSource);
                }
            },
            legs(){
               return this.$store.state[this.namespace].legs;
            }
        }
    }
</script>
